import React, { useState,useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Grid, Typography, Card, CardContent } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import EnhancedTable from "../../components/tables/DashboardTable";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../login/loginSlice";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import BallotOutlinedIcon from "@mui/icons-material/BallotOutlined";
import AttractionsOutlinedIcon from "@mui/icons-material/AttractionsOutlined";
import DeviceHubOutlinedIcon from "@mui/icons-material/DeviceHubOutlined";
import Menubar from "../menu-bar/menu-bar";
import {
  selectRovers,getReport
} from "../../pages/rover/roverSlice";

const Dashboard = () => {
  const rovers = useSelector(selectRovers);
  const reports = useSelector(getReport);
  const [value, setValue] = useState({
    startDate: new Date(),
    endDate: new Date().setMonth(11),
  });

  const [selectedSection, setSelectedSection] = useState("overview");
  const [isRoverSubMenuVisible, setRoverSubMenuVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMenuClick = (section: string) => {
    if (section === "rover") {
      setRoverSubMenuVisible(!isRoverSubMenuVisible);
    } else {
      setSelectedSection(section);
      setRoverSubMenuVisible(false);
      navigate(`/${section}`);
    }
  };

  const handleLogoutClick = () => {
    localStorage.clear();
    dispatch(logoutUser());
    navigate("/");
  };

   // Calculate total number of rovers
   const totalRovers = rovers.length;
   const totalReports = reports.length;

   // Calculate number of Active and Inactive rovers
   const activeRovers = rovers.filter(rover => rover.status === 'Active').length;
   const inactiveRovers = rovers.filter(rover => rover.status === 'InActive').length;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Grid container sx={{ height: "100vh" }}>
        <Menubar /> 
        {/* Main Content */}
        <Grid item xs={12} md={10}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              // padding: 1,
              height: "100%",
            }}
          >
            {selectedSection === "overview" && (
              <>
                {/* Top Box */}
                <Box
                  sx={{
                    backgroundColor: "cornsilk",
                    height: "100%",
                    padding: 1,
                  }}
                >
                  <Box
                    sx={{
                      flex: 1,
                      marginRight: 2,
                      marginLeft: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "left",
                        justifyContent: "left",
                        left:10
                      }}
                    >
                      <Box
                        sx={{
                          color: "black",
                          position: "relative",
                          left: 7,
                          top: 5,
                        }}
                      >
                        <DashboardOutlinedIcon />
                      </Box>
                      <Typography
                        variant="h5"
                        sx={{
                          color: "black",
                          fontFamily: "monospace",
                          fontWeight: 600,
                          position: "relative",
                          left: 10,
                        }}
                      >
                        Overview
                      </Typography>
                    </Box>
                    {/* Chart Cards */}
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={3}>
                        <Card
                          sx={{
                            marginTop: 3,
                            marginLeft: 4,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "90%",
                            width: "80%",
                            background: "rgb(236, 242, 255)",
                            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                          }}
                        >
                          <CardContent>
                            <Typography
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "rgb(93, 135, 255)",
                              }}
                            >
                              <AnalyticsOutlinedIcon sx={{ fontSize: 70 }} />
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginBottom: 1,
                                fontFamily: "revert-layer",
                                fontWeight: 600,
                                color: "rgb(93, 135, 255)",
                                fontSize: 18,
                              }}
                            >
                              Total Rovers
                            </Typography>
                            <Typography
                              variant="h4"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "rgb(93, 135, 255)",
                                fontSize: 24,
                                fontWeight: 600,
                              }}
                            >
                              {totalRovers}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Card
                          sx={{
                            marginTop: 3,
                            marginLeft: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "90%",
                            width: "80%",
                            background: "rgb(254, 245, 229)",
                            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                          }}
                        >
                          <CardContent>
                            <Typography
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "rgb(255, 174, 31)",
                              }}
                            >
                              <BallotOutlinedIcon sx={{ fontSize: 70 }} />
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginBottom: 1,
                                fontFamily: "revert-layer",
                                fontWeight: 600,
                                color: "rgb(255, 174, 31)",
                                fontSize: 18,
                              }}
                            >
                              Reports
                            </Typography>
                            <Typography
                              variant="h4"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "rgb(255, 174, 31)",
                                fontSize: 24,
                                fontWeight: 600,
                              }}
                            >
                              {totalReports}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Card
                          sx={{
                            marginTop: 3,
                            marginLeft: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "90%",
                            width: "80%",
                            background: "rgb(230, 255, 250)",
                            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                          }}
                        >
                          <CardContent>
                            <Typography
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "rgb(19, 222, 185)",
                              }}
                            >
                              <DeviceHubOutlinedIcon sx={{ fontSize: 70 }} />
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginBottom: 1,
                                fontFamily: "revert-layer",
                                fontWeight: 600,
                                color: "rgb(19, 222, 185)",
                                fontSize: 18,
                              }}
                            >
                              Active Rovers
                            </Typography>
                            <Typography
                              variant="h4"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "rgb(19, 222, 185)",
                                fontSize: 24,
                                fontWeight: 600,
                              }}
                            >
                              {activeRovers}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Card
                          sx={{
                            marginTop: 3,
                            marginLeft: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "90%",
                            width: "80%",
                            background: "rgb(253, 237, 232)",
                            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                          }}
                        >
                          <CardContent>
                            <Typography
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "rgb(250, 137, 107)",
                              }}
                            >
                              <AttractionsOutlinedIcon sx={{ fontSize: 70 }} />
                            </Typography>
                            <Typography
                              variant="h6"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                marginBottom: 1,
                                fontFamily: "revert-layer",
                                fontWeight: 600,
                                color: "rgb(250, 137, 107)",
                                fontSize: 18,
                              }}
                            >
                              InActive Rovers
                            </Typography>
                            <Typography
                              variant="h4"
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                color: "rgb(250, 137, 107)",
                                fontSize: 24,
                                fontWeight: 600,
                              }}
                            >
                              {inactiveRovers}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    </Grid>
                  </Box>

                  {/* Bottom Box */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      marginTop: 4,
                      marginRight: 2,
                      marginLeft: 3,
                      //backgroundColor: "rgb(255, 255, 255)",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "start",
                        justifyContent: "start",
                        width: "100%",
                        marginBottom: 2,
                      }}
                    >
                      <Box
                        sx={{
                          marginTop: 1,
                          color: "black",
                        }}
                      >
                        <StickyNote2OutlinedIcon />
                      </Box>
                      <Typography
                        variant="h6"
                        sx={{
                          fontFamily: "monospace",
                          fontWeight: 600,
                          color: "black",
                          fontSize: 25,
                          marginLeft: 1,
                        }}
                      >
                        Report
                      </Typography>
                    </Box>
                    <EnhancedTable />
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
