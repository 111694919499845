import axios from 'axios';
import { env_var } from '../config/env';
import { history } from '../helpers/history';
import { getHeaderInfo } from '../helpers/tokenCreator';
import { removeTokens } from './localStorage';

const handleResponse = (response: any) => {
    if (!response) {
        throw new Error('Empty response received.');
    }

    if (response.status === 401) {
        removeTokens();
        history.push('/v1');
        window.location.reload();
        throw new Error('Unauthorized');
    }

    if (response.data && response.data !== 'OK') {
        throw new Error(`Request failed with status: ${response.data.status}`);
    }

    return response;
}

export const post = async function (url: string, body: any) {
    console.log(url,body);
    let header = await getHeaderInfo();
    try {
        let resp = await axios.post(url, body, header);
        return handleResponse(resp.data);
    } catch (err) {
        if (err.response) {           
            throw new Error(err.response.data.message || 'Request failed with status: ' + err.response.status);
        }
        throw new Error('Network error occurred.');
    }
};

export const get = async function (url: string, params: any = {}) {
    let header = await getHeaderInfo();
    try {
        let resp = await axios.get(env_var.BASE_URL + url, { ...header, params });
        return handleResponse(resp);
    } catch (err) {
        if (err.response) {
            return handleResponse(err.response);
        }
        throw new Error('Network error occurred.');
    }
};

export const put = async function (url: string, body: any) {
    console.log(url,body);
    let header = await getHeaderInfo();
    try {
        let resp = await axios.put(url, body, header);
        return handleResponse(resp.data);
    } catch (err) {
        if (err.response) {           
            throw new Error(err.response.data.message || 'Request failed with status: ' + err.response.status);
        }
        console.log(err);
        throw new Error('Network error occurred.');
    }
};

export const deleteApi = async function (url: string) {
    let header = await getHeaderInfo();
    try {
        let resp = await axios.delete(url, header);
        return handleResponse(resp.data);
    } catch (err) {
        if (err.response) {           
            throw new Error(err.response.data.message || 'Request failed with status: ' + err.response.status);
        }
        throw new Error('Network error occurred.');
    }
};
