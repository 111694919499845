import React, { useEffect, useState, useRef } from 'react';
import {
    Box,
    Button,
    FormControl,
    AppBar,
    Input,
    Switch,
    FormControlLabel,
    Toolbar
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import BatteryStatus from './Batterystatus';
import "../../assets/styles/directDrive.css";
import SignalStrength from './Signalstrength';
import GpsStatus from './Gpsdata';
import { RootState, AppDispatch } from '../../redux/store';
import { fetchRoverStatus, setBatteryRemaining, setSignalStrength, setGpsData, addRoverDetail } from '../../pages/rover/roverSlice';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import LiveMap from "./LiveMap";
import GPSHistory from "./GPSHistory";
import CameraView from "./CameraView";

const DirectDrive: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [roomID, setRoomID] = useState('');
  const [connected, setConnected] = useState(false);
  const [showMap, setShowMap] = useState(true);
  const [showCamera, setShowCamera] = useState(false);

  const navigate = useNavigate();
  const { roverId } = useParams<{ roverId: string }>();
  const batteryRemaining = useSelector((state: RootState) => state.rover.batteryRemaining);
  const signalStrength = useSelector((state: RootState) => state.rover.signalStrength);
  const gpsData = useSelector((state: RootState) => state.rover.gpsData);
  const wsRoom1 = useRef<WebSocket | null>(null);
  const pressInterval = useRef<NodeJS.Timeout | null>(null);
  const pressStartTime = useRef<number | null>(null);
  const [direction, setDirection] = useState<string>('');
  const [showHistory, setShowHistory] = useState(false);
    useEffect(() => {
        if (roverId) {
            setRoomID(roverId);
            dispatch(fetchRoverStatus());
        }
    }, [roverId, dispatch]);

    const connectToRoom = (roomID: string) => {
        const wsInstance = new WebSocket('wss://websocket.rovertech.live:9000/');

        wsInstance.onopen = () => {
            console.log(`WebSocket connection to ${roomID} opened`);
            wsInstance.send(JSON.stringify({ action: 'joinroom', room: roomID }));
            setConnected(true);
        };

        wsInstance.onmessage = (event: MessageEvent) => {
            try {
                const status = JSON.parse(event.data);
                if (status.battery_remaining !== undefined) {
                	dispatch(setBatteryRemaining(status.battery_remaining));
                }
                if (status.signal_strength !== undefined) {
                	dispatch(setSignalStrength(status.signal_strength));
                }
                if (status.latitude !== undefined && status.longitude !== undefined) {
          	dispatch(
	            setGpsData({
	              latitude: parseFloat(status.latitude),
	              longitude: parseFloat(status.longitude),
	              altitude: parseFloat(status.altitude),
	              roverId: roomID,
            })
          );
        }

        const roverStatusMessage = JSON.stringify({
          action: "roverStatus",
          ...status,
        });
        console.log("Sending roverStatus message:", roverStatusMessage);
        wsInstance.send(roverStatusMessage);
      } catch (error) {
        console.error("Error parsing message data:", error);
      }
    };

        wsInstance.onclose = () => {
            console.log(`WebSocket connection to ${roomID} closed`);
            setConnected(false);
            setRoomID('');
        };

        wsInstance.onerror = (error) => {
            console.error(`WebSocket error in ${roomID}:`, error);
        };

        wsRoom1.current = wsInstance;
    };

    const handleConnect = () => {
        if (roomID) {
            connectToRoom(roomID);
        } else {
            console.error('Room ID is required to connect');
        }
    };

    const handleDisconnect = () => {
        if (wsRoom1.current && wsRoom1.current.readyState === WebSocket.OPEN) {
            wsRoom1.current.close();
        }
    };

    const sendCommand = (command: string) => {
        const wsInstance = wsRoom1.current;
        if (wsInstance && wsInstance.readyState === WebSocket.OPEN) {
            wsInstance.send(JSON.stringify({ action: 'sendmessage', room: roomID, message: command }));
        } else {
            console.log('WebSocket connection is not open');
        }
    };

    useEffect(() => {
        if (connected) {
            handleAddRoverDetail();
        }
    }, [batteryRemaining, signalStrength, gpsData, connected]);

    const handleAddRoverDetail = async () => {
        const newRover = {
            RoverID: roverId,
            Battery: batteryRemaining,
            Signal: signalStrength,
            GPS: gpsData
        };
        const response = await dispatch(addRoverDetail(newRover));
        console.log("response", response);

    };

    const handleMouseDown = (direction: string) => {
        setDirection(direction);
        pressStartTime.current = Date.now();
        console.log(`Mouse down on ${direction}`);
    };

      useEffect(() => {
        if (pressStartTime.current !== null && direction) {
          pressInterval.current = setInterval(() => {
            if (pressStartTime.current !== null) {
              console.log("Interval running");
              const pressDuration = (Date.now() - pressStartTime.current) / 1000;
              console.log(`Press duration: ${pressDuration}`);
              const speed = Math.min(pressDuration / 10, 1);
              console.log(`Speed: ${speed}`);
              sendCommand(`${direction}:${speed}`);
            }
          }, 100);
        } else if (pressInterval.current) {
          clearInterval(pressInterval.current);
          pressInterval.current = null;
        }
    
        return () => {
          if (pressInterval.current) {
            clearInterval(pressInterval.current);
          }
        };
      }, [direction]);
    
      const handleMouseUp = () => {
        if (pressInterval.current) {
          clearInterval(pressInterval.current);
          pressInterval.current = null;
        }
        pressStartTime.current = null;
        sendCommand(`${direction}:-1`);
        console.log(`Mouse up on ${direction}`);
      };

    const handleBack = () => {
        navigate(-1); // Navigate back to the previous page
	    };

  const toggleMap = () => {
    setShowMap(!showMap);
    setShowHistory(false);
    setShowCamera(false);
  };
  
  const toggleHistory = () => {
    setShowHistory(!showHistory);
    setShowMap(false);
    setShowCamera(false);
  };
  
  const toggleCamera = () => {
    setShowCamera(!showCamera);
    setShowMap(false);
    setShowHistory(false);
  };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                padding: 2,
                position: 'relative',
                background: "dimgray",
            }}
        >
            <Box
                sx={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
         	    right: 510,
                    top: 10
                }}
            >
        <Box
          sx={{
            position: "relative",
            top: -185,
            left: "19%",          
            display: "flex",
            gap: 2,         
          }}
        >
         
          <Button
            variant="contained"
            sx={{
              backgroundColor: "darkorange",
              color: "white",
              padding: "0px 10px",
              borderRadius: "5px",
              width: "auto",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              "&:hover": {
                backgroundColor: "#ffb84d",
                boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.2)",
              },
            }}
            onClick={toggleMap}
          >
            GPS Live
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "darkorange",
              color: "white",
              padding: "7px 10px",
              borderRadius: "5px",
              width: "auto",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              "&:hover": {
                backgroundColor: "#ffb84d",
                boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.2)",
              },
            }}
            onClick={toggleHistory}
          >
           Tracking History
          </Button>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "darkorange",
              color: "white",
              padding: "0px 10px",
              borderRadius: "5px",
              width: "auto",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
              "&:hover": {
                backgroundColor: "#ffb84d",
                boxShadow: "0px 6px 8px rgba(0, 0, 0, 0.2)",
              },
            }}
            onClick={toggleCamera}
          >
            Camera        
          </Button>
        </Box>
        {showHistory && <GPSHistory roomID={roomID}/>}
        {showMap && <LiveMap />}
        {showCamera && <CameraView/>}
       
      </Box>
      <AppBar position="absolute" sx={{ backgroundColor: "#b86b3f" }}>
        <Toolbar>
          <Box
            sx={{
              position: "absolute",
              top: 10,
              right: 16,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              gap: 2,
            }}
          >
            <BatteryStatus batteryRemaining={batteryRemaining} />
            <SignalStrength signalStrength={signalStrength} />
            <GpsStatus gpsData={gpsData} />
          </Box>
          <Box>
            <Button
              className="back_button"
              onClick={handleBack}
              startIcon={<ArrowBackOutlinedIcon />}
            >
              Back
            </Button>
          </Box>
         
        </Toolbar>
      </AppBar>

            <Box className='white_backgrd' sx={{ position: "absolute", right: 80 }}>
        
                <Box className="outercircle" sx={{
                    width: 300,
                    height: 300,
                    borderRadius: '50%',
                    border: '10px solid #ccc',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                }}>
                    <Box className='directDriveBtn' sx={{ position: 'relative', width: 200, height: 200 }}>
                        <Box className="innercircle"
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 50,
                                height: 50,
                                borderRadius: '50%',
                                backgroundColor: '#f0f0f0'
                            }}
                        >
                        </Box>

                        <Button
                            className='forwardDrive'
                            onMouseDown={() => handleMouseDown("forward")}
                            onMouseUp={() => handleMouseUp()}
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: 50,
                                height: 60,
                                border: '1px solid black',
                                borderRadius: '50%',
                                backgroundColor: '#f0f0f0',
                                "&:hover": {
                                    background: "#05e8f1",
                                },
                            }}
                        >
                            <ArrowRightOutlinedIcon sx={{ transform: 'rotate(-90deg)', width: 50, height: 50, color: "black" }} />
                        </Button>
                        <Button
                            className='rightDrive'
                            onMouseDown={() => handleMouseDown("right")}
                            onMouseUp={() => handleMouseUp()}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                right: 0,
                                transform: 'translate(50%, -50%)',
                                width: 50,
                                height: 60,
                              border: '1px solid black',
                                borderRadius: '50%',
                                backgroundColor: '#f0f0f0',
                                "&:hover": {
                                    background: "#05e8f1",
                                },
                            }}
                        >
                            <ArrowRightOutlinedIcon sx={{ width: 50, height: 50, color: "black" }} />
                        </Button>
                        <Button
                            className='leftDrive'
                            onMouseDown={() => handleMouseDown("left")}
                            onMouseUp={() => handleMouseUp()}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: 0,
                                transform: 'translate(-50%, -50%)',
                                width: 50,
                                height: 60,
                                border: '1px solid black',
                                borderRadius: '50%',
                                backgroundColor: '#f0f0f0',
                                "&:hover": {
                                    background: "#05e8f1",
                                },
                            }}
                        >
                            <ArrowRightOutlinedIcon sx={{ transform: 'rotate(180deg)', width: 50, height: 50, color: "black" }} />
                        </Button>
                        <Button
                            className='backwardDrive'
                            onMouseDown={() => handleMouseDown("backward")}
                            onMouseUp={() => handleMouseUp()}
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                left: '50%',
                            transform: 'translate(-50%, 50%)',
                                width: 50,
                                height: 60,
                                border: '1px solid black',
                                borderRadius: '50%',
                                backgroundColor: '#f0f0f0',
                                "&:hover": {
                                    background: "#05e8f1",
                                },
                            }}
                        >
                            <ArrowRightOutlinedIcon sx={{ transform: 'rotate(90deg)', width: 50, height: 50, color: "black" }} />
                        </Button>
                    </Box>
                </Box>
            </Box>
            <Box>
                <Button
                    className='Arm'
                    sx={{
                        position: 'absolute',
                        top: '85%',
                        left: 850,
                        transform: 'translate(50%, -50%)',
                        width: 80,
                        height: 70,
                        color: 'black',
                        border: '1px solid black',
                        borderRadius: '50%',
                        backgroundColor: '#f0f0f0',
                        "&:hover": {
                            background: "#ffa700",
                        },
                    }}
                    onClick={() => sendCommand("ARM")}
                >
                    ARM
                </Button>
                <Button
                    className='Arm'
                    sx={{
                        position: 'absolute',
                        top: '85%',
                        left: 1100,
                        transform: 'translate(50%, -50%)',
                        width: 80,
                        height: 70,
                        color: 'black',
                        border: '1px solid black',
                        borderRadius: '50%',
                        backgroundColor: '#f0f0f0',
                        "&:hover": {
                            background: "#ffa700",
                        },
                    }}
                  onClick={() => sendCommand("DISARM")}
                >
                    DISARM
                </Button>
            </Box>
            <Box className="connecting_Rooms" sx={{ marginTop: 9 }}>
                <FormControl
                    sx={{
                        backgroundColor: 'white',
                        borderRadius: 3, // Optional: adds rounded corners
                        width: 120,
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Optional: adds a subtle shadow
                    }}
                >
                    <Input
                        id="room-id-input"
                        value={roomID}
                        disabled
                        sx={{
                            backgroundColor: 'white',
                            padding: '8px 12px',
                            borderRadius: 3,
                            color: "black",
                            fontWeight: "bold"
                        }}
                    />
                </FormControl>
                <Button className="room_button" onClick={connected ? handleDisconnect : handleConnect}>
                    {connected ? 'Disconnect' : 'Connect'}
                </Button>
            </Box>
        </Box>
    );
};

export default DirectDrive;
