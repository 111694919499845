import React from "react";
import { Box, Typography } from "@mui/material";

const CameraView: React.FC = () => {
  return (
    <Box
      sx={{
        width: '115vh',
        height: '65vh',

        marginTop: 2,
        position: "absolute",
        bottom: 25,
        ml: 72,
        zIndex: 1,
        borderRadius: 2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden'
      }}
    >
      <video
        autoPlay
        loop
        muted
        style={{
          width: "100%",
          maxWidth: "100%",
          borderRadius: "10px",
          boxShadow: "0 2px 10px rgba(0,0,0,0.3)",
          position: "relative",
          zIndex: 0,
        }}
      >
        <source src="/assets/images/sample.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    
    </Box>
  );
};

export default CameraView;
