import React from 'react';
import { Box, Typography } from '@mui/material';
import SignalCellular0BarIcon from '@mui/icons-material/SignalCellular0Bar';
import SignalCellular1BarIcon from '@mui/icons-material/SignalCellular1Bar';
import SignalCellular2BarIcon from '@mui/icons-material/SignalCellular2Bar';
import SignalCellular3BarIcon from '@mui/icons-material/SignalCellular3Bar';
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar';

interface SignalStrengthProps {
  signalStrength: number;
}

const SignalStrength: React.FC<SignalStrengthProps> = ({ signalStrength }) => {
  const getSignalIcon = (signalStrength: number) => {
    if (signalStrength > -70) {
      return <SignalCellular4BarIcon style={{ color: '#00e676' }} />;
    } else if (signalStrength > -86) {
      return <SignalCellular3BarIcon style={{ color: '#ffeb3b' }} />;
    } else if (signalStrength > -100) {
      return <SignalCellular2BarIcon style={{ color: '#ff9800' }} />;
    } else {
      return <SignalCellular1BarIcon style={{ color: 'rgb(255 255 255)' }} />;
    }
  };

  return (
    <Box
      style={{
        position: 'absolute',
        right: 145,
        display: 'flex',
        alignItems: 'center',
        gap: 8,     
        padding: '8px 16px',
        borderRadius: 8     
      }}
    >
      {getSignalIcon(signalStrength)}
      {/* <Box
        style={{
          width: 100,
          height: 20,
          backgroundColor: '#e0e0e0',
          borderRadius: 10,
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Box
          style={{
            width: `${Math.min((signalStrength + 100) / 30 * 100, 100)}%`,
            height: '100%',
            backgroundColor: signalStrength > -70 ? '#00e676' : signalStrength > -86 ? '#ffeb3b' : signalStrength > -100 ? '#ff9800' : '#f44336',
            borderRadius: 10,
          }}
        />
        <Typography
          variant="body2"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: '#000',
            fontWeight: 'bold',
          }}
        >
          {signalStrength}dBm
        </Typography>
      </Box> */}
    </Box>
  );
};

export default SignalStrength;
