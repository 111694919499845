import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../redux/store';
import { getlogin } from '../../services/loginService';
import { history } from '../../helpers/history'; 
interface LoginState {
  email: string;
  password: string;
  isLoadingLogin: boolean;
  accessToken: string | null;
  userId: string | null;
  error: string | null;
  isAuthenticated: boolean;
}

const initialState: LoginState = {
  email: '',
  password: '',
  isLoadingLogin: false,
  accessToken: null,
  userId: null,
  error: null,
  isAuthenticated: false,
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setEmail(state, action: PayloadAction<string>) {
      state.email = action.payload;
    },
    setPassword(state, action: PayloadAction<string>) {
      state.password = action.payload;
    },
    resetLoginState(state) {
      state.email = '';
      state.password = '';
      state.isLoadingLogin = false;
      state.accessToken = null;
      state.userId = null;
      state.error = null;
      state.isAuthenticated = false;
    },
    start(state) {
      state.isLoadingLogin = true;
      state.error = null;
    },
    success(state, action: PayloadAction<{ token: string, _id: string }>) {
      state.isLoadingLogin = false;
      state.accessToken = action.payload.token;
      state.userId = action.payload._id;
      state.isAuthenticated = true;
      state.error = null;
    },
    error(state, action: PayloadAction<string>) {
      state.isLoadingLogin = false;
      state.error = action.payload;
    },
    logoutUser(state) {
      state.isAuthenticated = false; // Reset isAuthenticated to false on logout
      state.email = '';
      state.password = '';
    },
  },
});

export const { setEmail, setPassword, resetLoginState, start, success, error,logoutUser } = loginSlice.actions;

export const getUserLogin = (email: string, password: string): AppThunk => async (dispatch) => {
  dispatch(start());
  try {
    const { token, _id } = await getlogin(email, password);
    dispatch(success({ token, _id }));   
     localStorage.setItem('accessToken', token);
     localStorage.setItem('userId', _id);
     localStorage.setItem('email', email);    
    history.push('/dashboard'); 
  } catch (err) {
    dispatch(error(err.message || 'Failed to login'));
  }
};

export const selectLogin = (state: RootState) => state.login;

export default loginSlice.reducer;
