import React from 'react';
import { Navigate, Route , Routes } from 'react-router-dom';
import Login from "../pages/login/Login";
import Dashboard  from '../pages/dashboard/Dashboard';
import ListRover from '../pages/listrover/ListRover';
import Rover from '../pages/rover/Rover';
import Reports from '../pages/reports/Reports';
import ScheduleReports from '../pages/reports/ScheduleReports';


export default function Router(): JSX.Element
{
    return (
        <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} /> 
        <Route path="/listrover" element={<ListRover />} /> 
        <Route path="/reports" element={<Reports />} /> 
        <Route path="/schedulereports" element={<ScheduleReports />} /> 
        <Route path="/roverdrive/:roverId" element={<Rover />} />
        </Routes>
    );
}

