import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import {
  Button,
  Box,
  TextField,
  Modal,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Parser } from 'json2csv';
import printJS from 'print-js';
import {
  updaterovers,
  errorState,
} from "../../pages/listrover/listRoverSlice";
import {
    selectRovers,
    fetchRoverStatus,
    getRoverReport,
    getReport
  } from "../../pages/rover/roverSlice";
import { AppDispatch } from "../../redux/store";
import "../../assets/styles/reportTable.css";

type ListRowData = {
  _id: string;
  roverId: string;
  roverName: string;
  status: string;
  lastActive: string;
};

type getReport = {
    roverId: string;
}

const DataTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const rovers = useSelector(selectRovers);
  const report = useSelector(getReport);
  const updaterover = useSelector(updaterovers);
  const error = useSelector(errorState);
  const [edit, setEdit] = useState(false);
  const [opendelete, setDelete] = useState(false);
  const [addRoverModal, setAddRoverModal] = useState(false);
  const [roverID, setRoverID] = useState("");
  const [roverName, setRoverName] = useState("");
  const [status, setStatus] = useState("");
  const [lastActive, setLastActive] = useState("");
  const [selectedRoverId, setSelectedRoverId] = useState<string | null>(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");


  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchRoverStatus());
  }, [updaterover]);

  const columns: GridColDef[] = [
    { field: "roverId", headerName: "Rover ID", flex: 1 },
    { field: "roverName", headerName: "Rover Name", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    { field: "lastActive", headerName: "Last Active", flex: 1 },
    { field: "signal", headerName: "Signal Strength", flex: 1 },
    { field: "battery", headerName: "Battery Remaining (%)", flex: 1 },
  ];

  const formattedRovers = rovers.map((rover, index) => ({
    ...rover,
    id: `${index}`,
  }));

  const handleRowClick: GridEventListener<"rowClick"> = (params, event) => {
    event.stopPropagation();
  };

  const handleCellClick: GridEventListener<"cellClick"> = (params, event) => {
    if (params.field === "__check__") {
      return;
    }
    event.stopPropagation();
  };

  const CustomToolbar = () => {
    return (
        <GridToolbarContainer sx={{ display: "flex", justifyContent: "flex-end" }}>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  return (
    <div style={{ height: "calc(100vh - 90px)", width: "100%" }}>
      <div style={{ marginBottom: "20px", padding: "10px" }}>
        <h1>Reports</h1>
        {/* <div style={{ display: "flex", justifyContent: "end" }}>
          <Button
            variant="contained"
            onClick={handleOpen}
            sx={{
              background: "#ffa700",
              "&:hover": {
                background: "#ffa700",
              },
            }}
          >
            Preview
          </Button>
        </div> */}
      </div>
      <div style={{ height: "calc(100% - 80px)" }}>
        <DataGrid
          rows={formattedRovers}
          columns={columns}
          checkboxSelection
          pagination
          onRowClick={handleRowClick}
          onCellClick={handleCellClick}
        />
      </div>
    </div>
  );
};

export default DataTable;
