import { configureStore, combineReducers, ThunkAction, Action } from '@reduxjs/toolkit';
import loginSliceReducer from '../pages/login/loginSlice';
import listRoverSliceReducer from '../pages/listrover/listRoverSlice';
import roverReducer from '../pages/rover/roverSlice';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const rootReducer = combineReducers({
  login: loginSliceReducer,
  listRover:listRoverSliceReducer,
  rover: roverReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;