import React from 'react';
import { Box, Typography } from '@mui/material';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatteryCharging50Icon from '@mui/icons-material/BatteryCharging50';
import Battery20Icon from '@mui/icons-material/Battery20';

interface BatteryStatusProps {
  batteryRemaining: number;
}

const BatteryStatus: React.FC<BatteryStatusProps> = ({ batteryRemaining }) => {
  const getBatteryIcon = (batteryRemaining: number) => {
    if (batteryRemaining > 50) {
      return <BatteryFullIcon style={{ color: getBatteryColor(batteryRemaining) }} />;
    } else if (batteryRemaining > 25) {
      return <BatteryCharging50Icon style={{ color: getBatteryColor(batteryRemaining) }} />;
    } else {
      return <Battery20Icon style={{ color: getBatteryColor(batteryRemaining) }} />;
    }
  };

  const getBatteryColor = (batteryRemaining: number) => {
    if (batteryRemaining > 75) {
      return '#00e676';
    } else if (batteryRemaining > 50) {
      return '#ffeb3b';
    } else if (batteryRemaining > 25) {
      return '#ff9800';
    } else {
      return 'rgb(255 255 255)';
    }
  };

  return (
    <Box
      style={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        gap: 8,
        padding: '8px 16px',
        borderRadius: 8
      }}
    >
      {getBatteryIcon(batteryRemaining)}
      <Box
        style={{
          width: 100,
          height: 20,
          backgroundColor: '#e0e0e0',
          borderRadius: 10,
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <Box
          style={{
            width: `${batteryRemaining}%`,
            height: '100%',
            backgroundColor: getBatteryColor(batteryRemaining),
            borderRadius: 10,
          }}
        />
        <Typography
          variant="body2"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: '#000',
            fontWeight: 'bold',
          }}
        >
          {batteryRemaining}%
        </Typography>
      </Box>
    </Box>
  );
};

export default BatteryStatus;
