import React from "react";
import Menubar from "../menu-bar/menu-bar";
import ReportTable from "../../components/tables/ReportTable";
import { Box, Grid } from "@mui/material";

const ListRover = () => {
  return (
    <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
    }}
  >
    <Grid container sx={{ height: "100vh" }}>
      <Menubar />  
      <Grid item xs={12} md={10}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >   
      <ReportTable />
      </Box>
      </Grid>      
      </Grid>
    </Box>
  );
};

export default ListRover;
