import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Grid, Typography } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import dashboardImage from "../../assets/images/dashboard_bar.png";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import CoPresentOutlinedIcon from "@mui/icons-material/CoPresentOutlined";
import AssignmentIcon from '@mui/icons-material/Assignment';
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../login/loginSlice";


const Menubar = () => {
  const [selectedSection, setSelectedSection] = useState("dashboard");
  const [isRoverSubMenuVisible, setRoverSubMenuVisible] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMenuClick = (section: string) => {
    if (section === "rover") {
      setRoverSubMenuVisible(!isRoverSubMenuVisible);
    } else {
      setSelectedSection(section);
      setRoverSubMenuVisible(false);
      navigate(`/${section}`);
    }
  };

  const handleLogoutClick = () => {
    localStorage.clear();
    dispatch(logoutUser());
    navigate("/");
  };

  return (
    <Grid
          item
          xs={12}
          md={2}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundImage: `url(${dashboardImage})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: "transparent",
            borderColor: "black",
            backgroundSize: "cover",
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: "column", marginBottom: "auto" }}
          >
            <Box
              sx={{
                color: "white",
                position: "relative",
                right: 35,
                top: 28,
              }}
            >
              <MenuIcon sx={{ fontSize: 25 }} />
            </Box>
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600,
                marginBottom: 2,
                color: "white",
                fontSize: 20,
              }}
            >
              LAWNSHARK
            </Typography>
            <Box
              sx={{
                color: "white",
                position: "relative",
                right: 25,
                top: 28,
              }}
              onClick={() => handleMenuClick("dashboard")}
            >
              <Box
                sx={{
                  position: "relative",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "black",
                    "& > .icon": {
                      color: "black",
                    },
                  },
                  display: "flex",
                  alignItems: "center",
                  padding: 1,
                  borderRadius: 4,
                }}
              >
                <Box
                  sx={{
                    color: "white",
                    marginRight: 1,
                    position: "absolute",
                    left: 2,
                  }}
                  className="icon"
                >
                  <DashboardOutlinedIcon />
                </Box>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 600,
                    marginBottom: 1,
                    color: "inherit",
                    fontSize: 16,
                    marginLeft: 4,
                  }}
                >
                  Dashboard
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                color: "white",
                position: "relative",
                right: 25,
                top: 28,
              }}
              onClick={() => handleMenuClick("listrover")} 
            >
              <Box
                sx={{
                  position: "relative",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "black",
                    "& > .icon": {
                      color: "black",
                    },
                  },
                  display: "flex",
                  alignItems: "center",
                  padding: 1,
                  borderRadius: 4,
                }}
              >
                <Box
                  sx={{
                    color: "white",
                    marginRight: 1,
                    position: "absolute",
                    left: 2,
                  }}
                  className="icon"
                >
                  <CoPresentOutlinedIcon />
                </Box>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 600,
                    marginBottom: 1,
                    color: "inherit",
                    fontSize: 16,
                    marginLeft: 4,
                  }}
                >
                  Rovers
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                color: "white",
                position: "relative",
                right: 25,
                top: 28,
              }}
              onClick={() => handleMenuClick("schedulereports")} 
            >
              <Box
                sx={{
                  position: "relative",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "black",
                    "& > .icon": {
                      color: "black",
                    },
                  },
                  display: "flex",
                  alignItems: "center",
                  padding: 1,
                  borderRadius: 4,
                }}
              >
                <Box
                  sx={{
                    color: "white",
                    marginRight: 1,
                    position: "absolute",
                    left: 2,
                  }}
                  className="icon"
                >
                  <AssignmentIndIcon />
                </Box>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 600,
                    marginBottom: 1,
                    color: "inherit",
                    fontSize: 16,
                    marginLeft: 4,
                  }}
                >
                  Schedule Reports
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                color: "white",
                position: "relative",
                right: 25,
                top: 28,
              }}
              onClick={() => handleMenuClick("reports")} 
            >
              <Box
                sx={{
                  position: "relative",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "black",
                    "& > .icon": {
                      color: "black",
                    },
                  },
                  display: "flex",
                  alignItems: "center",
                  padding: 1,
                  borderRadius: 4,
                }}
              >
                <Box
                  sx={{
                    color: "white",
                    marginRight: 1,
                    position: "absolute",
                    left: 2,
                  }}
                  className="icon"
                >
                  <AssignmentIcon />
                </Box>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 600,
                    marginBottom: 1,
                    color: "inherit",
                    fontSize: 16,
                    marginLeft: 4,
                  }}
                >
                  Reports
                </Typography>
              </Box>
            </Box>            
            <Box
              sx={{
                color: "white",
                position: "relative",
                right: 25,
                top: 28,
                width: 150,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "black",
                    "& > .icon": {
                      color: "black",
                    },
                  },
                  display: "flex",
                  alignItems: "center",
                  padding: 1,
                  borderRadius: 4,
                }}
              >
                <Box
                  sx={{
                    color: "white",
                    marginRight: 1,
                    position: "absolute",
                    left: 2,
                  }}
                  className="icon"
                >
                  <SettingsOutlinedIcon />
                </Box>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 600,
                    color: "inherit",
                    fontSize: 16,
                    marginLeft: 4,
                  }}
                >
                  Setting
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                borderBottom: 1,
                color: "white",
                marginTop: 38,
                width: 255,
                position: "absolute",
                left: 1,
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  top: 320,
                  left: 45,
                  width: 100,
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "black",
                    "& > .icon": {
                      color: "black",
                    },
                  },
                  display: "flex",
                  alignItems: "center",
                  padding: 1,
                  borderRadius: 4,
                }}
                onClick={() => handleLogoutClick()}
              >
                <Box sx={{ color: "white", marginRight: 1 }} className="icon">
                  <AccountCircleOutlinedIcon />
                </Box>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 600,
                    marginBottom: 0,
                    color: "inherit",
                    fontSize: 16,
                  }}
                >
                  Logout
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>  
  );
};

export default Menubar;
