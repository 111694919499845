import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridToolbarContainer,
  GridToolbarExport,
  GridRenderCellParams
} from "@mui/x-data-grid";
import {
  Button,
  Box,
  TextField,
  Modal,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  updaterovers,
  errorState,
} from "../../pages/listrover/listRoverSlice";
import {
  selectRovers,
  getRoverReport,
  getReport,
  generatedReport,
  editReportAsync,
  deleteReportAsync
} from "../../pages/rover/roverSlice";
import { AppDispatch } from "../../redux/store";
import "../../assets/styles/reportTable.css";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

type getGenerateReport = {    
  reportName: string;
  email: string;
  status: string;
  reportData: string;
  scheduleData: string;
};

type getReportData = {
    _id: string;
    reportName: string;
    email: string;
    status: string;
    reportData: string;
    scheduleData: string;
  };

const DataTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const report = useSelector(getReport);
  const error = useSelector(errorState);
  const [edit, setEdit] = useState(false);
  const [opendelete, setDelete] = useState(false);
  const [addRoverModal, setAddRoverModal] = useState(false);
  const [emailID, setEmailID] = useState("");
  const [reportName, setReportName] = useState("");
  const [status, setStatus] = useState("");
  const [reportData, setReport] = useState("");
  const [scheduleData, setSchedule] = useState("");
  const [selectedRoverId, setSelectedRoverId] = useState<string | null>(null);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getRoverReport());
  }, [dispatch, getReport]);

  const handleOpen = () => setAddRoverModal(true);
  const handleClose = () => setAddRoverModal(false);

  const handleOpenEdit = () => setEdit(true);
  const handleCloseEdit = () => setEdit(false);

  const handleOpenDelete = () => setDelete(true);
  const handleCloseDelete = () => setDelete(false);

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailID(event.target.value);
  };

  const generateReport = async () => {
    const newGenerateReport: getGenerateReport = {
      reportName: reportName,
      email: emailID,
      status: status,
      reportData: reportData,
      scheduleData: scheduleData,
    };
    const response = await dispatch(generatedReport(newGenerateReport));
    if (response.meta.requestStatus === "fulfilled") {
    } else {
      console.log("error", error);
    }
  };

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    setStatus(event.target.value);
  };

  const handleReportChange = (event: SelectChangeEvent<string>) => {
    setReport(event.target.value);
  };

  const handleScheduleChange = (event: SelectChangeEvent<string>) => {
    setSchedule(event.target.value);
  };

  const handleRoverNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReportName(event.target.value);
  }


  const handleEditButtonClick = (row: getReportData) => {
    setSelectedRoverId(row._id);
    setReportName(row.reportName);
    setEmailID(row.email);
    setStatus(row.status);
    setReport(row.reportData);
    setSchedule(row.scheduleData);
    handleOpenEdit();
  };

  const handleDeleteButtonClick = (row: getReportData) => {
    setSelectedRoverId(row._id);
    setReportName(row.reportName);
    setEmailID(row.email);
    setStatus(row.status);
    setReport(row.reportData);
    setSchedule(row.scheduleData);
    handleOpenDelete();
  };

  const handleEditRover = async () => {
    const editedRover: getReportData = {
      _id: selectedRoverId!,
      reportName: reportName,
      email: emailID,
      status: status,
      reportData: reportData,
     scheduleData: scheduleData,
    };
  
    try {
      const response = await dispatch(editReportAsync(editedRover));
      if (response.meta.requestStatus === 'fulfilled') {
        handleCloseEdit();
      } else {
        console.error('Edit rover request failed:', response);
      }
    } catch (error) {
      console.error('Error editing rover:', error);
    }
  };  

  const handleDeleteRover = async () => {
    const deletedRover: getReportData = {
      _id: selectedRoverId!,
      reportName: reportName,
      email: emailID,
      status: status,
      reportData: reportData,
      scheduleData: scheduleData,
    };
    dispatch(deleteReportAsync(deletedRover));
    handleCloseDelete();
  };

  const columns: GridColDef[] = [
    { field: "reportName", headerName: "ReportName", flex: 1 },
    { field: "email", headerName: "Mail Notification To", flex: 1 },
    { field: "reportData", headerName: "Report Type", flex: 1 },
    { field: "scheduleData", headerName: "Frequency", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    {
      field: "Action",
      headerName: "Action",
      headerAlign: "center",
      flex: 1,
      renderCell: (params: GridRenderCellParams<getReportData>) => (
        <Box display="flex" justifyContent="space-evenly" width="100%">
          <BorderColorIcon
            sx={{
              color: "#25f2ee",
              marginTop: 2,
              fontSize: 24,
              cursor: "pointer",
            }}
            onClick={() => handleEditButtonClick(params.row)}
          />
          <DeleteForeverOutlinedIcon
            sx={{
              color: "red",
              marginTop: 2,
              fontSize: 24,
              cursor: "pointer",
            }}
            onClick={() => handleDeleteButtonClick(params.row)}
          />
        </Box>
      ),
    },
  ];

  const formattedRovers = report.map((rover, index) => ({
    ...rover,
    id: `${index}`,
  }));

  const handleRowClick: GridEventListener<"rowClick"> = (params, event) => {
    event.stopPropagation();
  };

  const handleCellClick: GridEventListener<"cellClick"> = (params, event) => {
    if (params.field === "__check__") {
      return;
    }
    event.stopPropagation();
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer sx={{ display: "flex", justifyContent: "flex-end" }}>
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  };

  return (
    <div style={{ height: "calc(100vh - 90px)", width: "100%" }}>
      <div style={{ marginBottom: "20px", padding: "10px" }}>
        <h1>Scheduled Reports</h1>
        <div style={{ display: "flex", justifyContent: "end" }}>
          <Button
            variant="contained"
            onClick={handleOpen}
            sx={{
              background: "#ffa700",
              "&:hover": {
                background: "#ffa700",
              },
            }}
          >
            ADD
          </Button>
        </div>
      </div>
      <div style={{ height: "calc(100% - 80px)" }}>
        <DataGrid
          rows={formattedRovers}
          columns={columns}
          checkboxSelection
          pagination
          onRowClick={handleRowClick}
          onCellClick={handleCellClick}
          slots={{
            toolbar: CustomToolbar,
          }}
        />
      </div>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={addRoverModal}
        onClose={handleClose}
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        sx={{
          display: "flex",
          p: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            height: "78%",
            width: "28%",
            borderRadius: "13px",
          }}
          noValidate
          autoComplete="off"
        >
          <div className="mod-AddRover">
            <p>Get Report</p>
          </div>
          <TextField
            required
            id="ReportName"
            label="ReportName"
            value={reportName}
            onChange={handleRoverNameChange}
            sx={{ marginBottom: "20px", width: "50%" }}
          />
          <FormControl sx={{ marginBottom: "20px", width: "50%" }}>
            <InputLabel id="Report Type">Report Type</InputLabel>
            <Select
              labelId="Report Type"
              id="Report-select"
              value={reportData}
              onChange={handleReportChange}
            >
              <MenuItem value="Battery">BatteryStatus</MenuItem>
              <MenuItem value="Signal">Signal Strength</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ marginBottom: "20px", width: "50%" }}>
            <InputLabel id="Scheduled-report">Frequency</InputLabel>
            <Select
              labelId="Scheduled-report"
              id="Schedule-select"
              value={scheduleData}
              onChange={handleScheduleChange}
            >
              <MenuItem value="Daily">Daily</MenuItem>
            </Select>
          </FormControl>
          <TextField
            required
            id="Email"
            label="Mail Notification To"
            value={emailID}
            onChange={handleEmailChange}
            sx={{ marginBottom: "20px", width: "50%" }}
          />
          <FormControl sx={{ marginBottom: "20px", width: "50%" }}>
            <InputLabel id="Status">Status</InputLabel>
            <Select
              labelId="Status"
              id="Status-select"
              value={status}
              onChange={handleStatusChange}
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "0 20px" }}>
            <Button variant="contained" onClick={handleClose} sx={{left:100}}>
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                background: "#ffa700",
                "&:hover": {
                  background: "#ffa700",
                },
                right:100,
              }}
              onClick={generateReport}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal
        open={edit}
        onClose={handleCloseEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 5,
          }}
        >
          <h2 id="modal-modal-title">{"Edit Rover"}</h2>
          <TextField
            required
            id="ReportName"
            label="ReportName"
            value={reportName}
            onChange={handleRoverNameChange}
            sx={{ marginBottom: "20px" }}
          />
          <FormControl sx={{ marginBottom: "20px", width: "50%" }}>
            <InputLabel id="Report Type">Report Type</InputLabel>
            <Select
              labelId="Report Type"
              id="Report-select"
              value={reportData}
              onChange={handleReportChange}
            >
              <MenuItem value="Battery">BatteryStatus</MenuItem>
              <MenuItem value="Signal">Signal Strength</MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ marginBottom: "20px", width: "50%" }} disabled>
            <InputLabel id="Scheduled-report">Frequency</InputLabel>
            <Select
              labelId="Scheduled-report"
              id="Schedule-select"
              value={scheduleData}
              onChange={handleScheduleChange}
            >
              <MenuItem value="Daily">Daily</MenuItem>
            </Select>
          </FormControl>
          <TextField
            required
            id="Email"
            label="Mail Notification To"
            value={emailID}
            onChange={handleEmailChange}
            sx={{ marginBottom: "20px" }}
          />
          <div>
            <Button
              variant="contained"
              onClick={handleEditRover}
              sx={{
                background: "#00b2e0",
                "&:hover": { background: "#00b2e0" },
              }}
            >
              Edit Rover
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={opendelete}
        onClose={handleCloseDelete}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
        sx={{
          display: "flex",
          p: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            height: "30%",
            width: "20%",
            borderRadius: "13px",
          }}
          noValidate
          autoComplete="off"
        >
          <Typography id="delete-modal-title" variant="h6" component="h2">
            Confirm Delete
          </Typography>
          <Typography id="delete-modal-description" sx={{ mt: 2,ml: 4,alignContent:"center" }}>
            Are you sure you want to delete this report?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", padding: "10px 10px" }}>
            <Button variant="contained" onClick={handleCloseDelete}
            sx={{ left: 30}}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                background: "red",
                "&:hover": {
                  background: "red",
                },
                right: 30
              }}
              onClick={handleDeleteRover}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default DataTable;
