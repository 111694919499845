import React from 'react';
import { Box, Typography } from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

interface GpsData {
  latitude: number;
  longitude: number;
  altitude: number;
}

interface GpsStatusProps {
  gpsData: GpsData;
}

const truncateCoordinate = (coordinate: number) => {
  const truncated = coordinate.toFixed(3);
  return truncated.length > 7 ? `${truncated.substring(0, 7)}...` : truncated;
};

const GpsStatus: React.FC<GpsStatusProps> = ({ gpsData }) => {
  const hasGpsData = gpsData.latitude !== 0 && gpsData.longitude !== 0;

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        sx={{
          position: 'absolute',
          right: 310,
          display: 'flex',
          alignItems: 'center',
          bottom: 20,
          color: hasGpsData ? 'green' : 'rgb(255 255 255)'
        }}
      >
        <LocationOnOutlinedIcon />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: 10,
          right: 190,
          display: 'flex',
          flexDirection: 'column',
          textWrap: 'nowrap',
          color: '#000',
          fontWeight: 'bold',
          backgroundColor: '#e0e0e0',
          borderRadius: 2,
          width: 100,
          height: 50,
          justifyContent: 'center',
          paddingLeft: 1,
          paddingRight: 1,
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          Lat {truncateCoordinate(gpsData.latitude / 1e7)}
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
          Long {truncateCoordinate(gpsData.longitude / 1e7)}
        </Typography>
      </Box>
    </Box>
  );
};

export default GpsStatus;
