
import { createSlice, createAsyncThunk, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';
import {
  addRoversdata,
  getRoverStatus,
  addReportStatus,
  deleteReportData,
  editReportData,
  getRoverReportStatus,
  getTotalRoverStatus,
  saveTrackingData, 
  fetchTrackingData 
} from '../../services/roverService';

interface GpsData {
  latitude: number;
  longitude: number;
  altitude: number;
  date: string;
  roverId: string;
}
interface SavePathNamePayload {
  roverId: string;
  pathName: string;
  startTime: string;
}
interface TrackingData {
  roverId: string;
  startTime: string;
  endTime?: string;
  pathName?: string;
  path: GpsData[];
}

interface Rover {
  _id: string;
  roverId: string;
  roverName: string;
  status: string;
  location: string;
  temperature: string;
  humidity: string;
  signalStrength: string;
  active: string;
  battery: number;
  signal: number;
  gps: GpsData;
}

  interface Report {
    _id: string;
    reportName: string;
    email: string;
    status: string;
    reportData: string;
    scheduleData: string;
  } 
  
interface ReportGenerated {
  reportName: string;
  email: string;
  status: string;
  reportData: string;
  scheduleData: string;
}

interface RoverState {
  rovers: Rover[];
  report: Report[];
  batteryRemaining: number;
  signalStrength: number;
  gpsData: GpsData;
  gpsHistory: GpsData[];
  trackingData: TrackingData[];
  isTracking: { [roverId: string]: boolean };
  isLoading: boolean;
  error: string | object | null;
  status: 'idle' | 'loading' | 'failed';
}

const initialState: RoverState = {
  rovers: [],
  report: [],
  batteryRemaining: 0,
  signalStrength: -100,
  gpsData: {
    latitude: 0,
    longitude: 0,
    altitude: 0,
    roverId: '',
    date: new Date().toISOString(),
  },
  gpsHistory: [],
  trackingData: [] as TrackingData[],
  isTracking: {} as { [key: string]: boolean },
  isLoading: false,
  error: null,
  status: 'idle',
};

export const fetchRoverStatus = createAsyncThunk('rover/fetchStatus', async () => {
  const response = await getRoverStatus();
  console.log("roverdata",response);
  return response;
});

export const addRoverDetail = createAsyncThunk('listRover/addRoverDetail', async (newRover: any, thunkAPI) => {
  try {
    const response = await addRoversdata(newRover);
    return response;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getTotalRover = createAsyncThunk('listRover/getTotalRover',async () => {
  const response = await getTotalRoverStatus();
  console.log("roverdata",response);
  return response;
});

export const getRoverReport = createAsyncThunk('listRover/getReport',async () => {
    const response = await getRoverReportStatus();
    console.log("getReport",response);
    return response;
})

export const generatedReport = createAsyncThunk('listRover/addReport',async (newGenerateReport:ReportGenerated) => {
  const response = await addReportStatus(newGenerateReport);
  console.log("roverReport",response);
  return response;
})

export const editReportAsync = createAsyncThunk('listRover/updateRover', async (updatedReport: Report) => {
  try {
    const response = await editReportData(updatedReport);
    console.log("edit",response);
    return response;
  } catch (error) {
    console.error('Error editing rover:', error);
    throw error
  }
});

export const deleteReportAsync = createAsyncThunk('listRover/deleteRover', async (reportId: Report) => {
  try {
    const response = await deleteReportData(reportId);
    console.log("delete",response);
    return response;
  } catch (error) {
    console.error('Error deleting rover:', error);
    throw error;
  }
});

export const saveTracking = createAsyncThunk(
  'rover/saveTracking',
  async (trackingData: TrackingData, thunkAPI) => {
    try {
      const response = await saveTrackingData(trackingData);
      console.log(response);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchTracking = createAsyncThunk(
  'rover/fetchTracking',
  async (roomID: string, thunkAPI) => {
    try {
      const response = await fetchTrackingData(roomID);
      return response;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const roverSlice = createSlice({
  name: 'rover',
  initialState,
  reducers: {
    setTrackingData: (state, action: PayloadAction<TrackingData[]>) => {
      state.trackingData = action.payload;
    },
    setBatteryRemaining: (state, action: PayloadAction<number>) => {
      state.batteryRemaining = action.payload;
      // console.log('Battery Remaining:', action.payload); // Log battery remaining
    },
    setSignalStrength: (state, action: PayloadAction<number>) => {
      state.signalStrength = action.payload;
      // console.log('Signal Strength:', action.payload); // Log signal strength
    },
    savePathName: (state, action: PayloadAction<{ roverId: string; pathName: string; startTime: string }>) => {
      const { roverId, pathName, startTime } = action.payload;
      const tracking = state.trackingData.find(track => track.roverId === roverId && track.startTime === startTime);
      if (tracking) {
        tracking.pathName = pathName;
      }
    },
    startTracking: (state, action: PayloadAction<string>) => {
      const roverId = action.payload;
      const existingTracking = state.trackingData.find(tracking => tracking.roverId === roverId && !tracking.endTime);
      if (!existingTracking) {
        const startTime = new Date().toISOString();
        state.trackingData.push({
          roverId,
          startTime,
          path: [],
        });
      }
      state.isTracking[roverId] = true;
    },
    stopTracking: (state, action: PayloadAction<string>) => {
      const roverId = action.payload;
      const stopTime = new Date().toISOString();
      const currentTracking = state.trackingData.find(tracking => tracking.roverId === roverId && !tracking.endTime);
      if (currentTracking) {
        currentTracking.endTime = stopTime;
      }
      state.isTracking[roverId] = false;
    },
   
    setGpsData: (state, action: PayloadAction<{ latitude: number, longitude: number, altitude: number, roverId: string }>) => {
      const { latitude, longitude, altitude, roverId } = action.payload;
      const date = new Date().toISOString();
      const gpsData = { latitude, longitude, altitude, date, roverId };

      state.gpsData = gpsData;

      if (state.isTracking[roverId]) {
        const currentTracking = state.trackingData.find(tracking => tracking.roverId === roverId && !tracking.endTime);
        if (currentTracking) {
          currentTracking.path.push(gpsData);
        }
      }
      state.gpsHistory.push(gpsData);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoverStatus.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchRoverStatus.fulfilled, (state, action: PayloadAction<Rover[]>) => {
        state.isLoading = false;
        state.rovers = action.payload.map(rover => ({
          ...rover,
          SignalStrengthLabel: getSignalStrengthLabel(rover.signal),
          BatteryPercentage: `${rover.battery}%`,
        }));
      })
      .addCase(fetchRoverStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch rovers';
      })
      .addCase(getRoverReport.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getRoverReport.fulfilled, (state, action: PayloadAction<Report[]>) => {
        state.isLoading = false;
        state.report = action.payload;
      })
      .addCase(getRoverReport.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch rovers';
    })
      .addCase(fetchTracking.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchTracking.fulfilled, (state, action: PayloadAction<TrackingData[]>) => {
        state.isLoading = false;
        state.trackingData = action.payload;
      })
      .addCase(fetchTracking.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch tracking data';
      })
      .addCase(saveTracking.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(saveTracking.fulfilled, (state, action: PayloadAction<TrackingData>) => {
        state.isLoading = false;
        state.trackingData.push(action.payload);
        console.log('Save Tracking Fulfilled:', action.payload);
      })
      .addCase(saveTracking.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to save tracking data';
        console.error('Save Tracking Rejected:', action.payload);

      });
  },
});

const getSignalStrengthLabel = (signal: number): string => {
  if (signal >= -30) return "Amazing";
  if (signal >= -67) return "Very Good";
  if (signal >= -70) return "Okay";
  if (signal >= -80) return "Not Good";
  return "Unusable";
}


const selectState = (state: RootState) => state.rover;

export const selectRovers = createSelector(selectState, (state) => state.rovers);

export const getReport = createSelector(selectState, (state) => state.report);

export const selectGpsHistory = createSelector(selectState, (state) => state.gpsHistory);
export const selectTrackingData = createSelector(selectState, (state) => state.trackingData);
export const selectIsTracking = createSelector(selectState, (state) => state.isTracking);
export const { setBatteryRemaining, setSignalStrength, setGpsData, savePathName, setTrackingData } = roverSlice.actions;

export const { startTracking, stopTracking } = roverSlice.actions;

export default roverSlice.reducer;