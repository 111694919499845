import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridEventListener,
} from "@mui/x-data-grid";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import SportsEsportsOutlinedIcon from "@mui/icons-material/SportsEsportsOutlined";
import {
  Button,
  Box,
  TextField,
  Modal,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  addRoverAsync,
  fetchRoversAsync,
  editRoverAsync,
  selectrovers,
  deleteRoverAsync,
  updaterovers,
  addroverstate,
  errorState,
  clearError
} from "../../pages/listrover/listRoverSlice";
import { AppDispatch } from "../../redux/store";
import "../../assets/styles/reportTable.css";

type ListRowData = {
  _id: string;
  roverId: string;
  roverName: string;
  status: string;
  lastActive: string;
};

const DataTable = () => {
  const dispatch = useDispatch<AppDispatch>();
  const rovers = useSelector(selectrovers);
  const updaterover = useSelector(updaterovers);
  const error = useSelector(errorState);
  const [edit, setEdit] = useState(false);
  const [opendelete, setDelete] = useState(false);
  const [addRoverModal, setAddRoverModal] = useState(false);
  const [roverID, setRoverID] = useState("");
  const [roverName, setRoverName] = useState("");
  const [status, setStatus] = useState("");
  const [lastActive, setLastActive] = useState("");
  const [selectedRoverId, setSelectedRoverId] = useState<string | null>(null);
  const [disabled, setDisabled] = useState(true); 
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchRoversAsync());
  }, [updaterover]);

  const handleOpen = () => setAddRoverModal(true);
  const handleClose = () => {
    setAddRoverModal(false);
  };
  const handleOpenEdit = () => setEdit(true);
  const handleCloseEdit = () => {
    dispatch(clearError()); 
    setEdit(false);
  };
  const handleOpenDelete = () => setDelete(true);
  const handleCloseDelete = () => setDelete(false);

  const handleRoverIDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRoverID(event.target.value);
  };

  const handleRoverNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRoverName(event.target.value);
  };

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    setStatus(event.target.value);
  };

  const handleLastActiveChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLastActive(event.target.value);
  };

  const handleAddRover = async () => {
    const newRover: ListRowData = {
      roverId: roverID,
      roverName: roverName,
      status: "Active",
      lastActive: new Date().toISOString().split("T")[0],
      _id: "",
    };    
    const response = await dispatch(addRoverAsync(newRover));  
    console.log("response",response)    
      if (response.meta.requestStatus === "fulfilled") {
        handleClose();
        setRoverID("");
        setRoverName("");
      }
      else{
        console.log('error', error);
      }    
  };

  const handleEditButtonClick = (row: ListRowData) => {
    setSelectedRoverId(row._id);
    setRoverID(row.roverId);
    setRoverName(row.roverName);
    setStatus(row.status);
    setLastActive(row.lastActive);
    handleOpenEdit();
  };

  const handleDeleteButtonClick = (row: ListRowData) => {
    setSelectedRoverId(row._id);
    setRoverID(row.roverId);
    setRoverName(row.roverName);
    setStatus(row.status);
    setLastActive(row.lastActive);
    handleOpenDelete();
  };

  const handleEditRover = async () => {
    const editedRover: ListRowData = {
      _id: selectedRoverId!,
      roverId: roverID,
      roverName: roverName,
      status: status,
      lastActive: lastActive,
    };
  
    try {
      const response = await dispatch(editRoverAsync(editedRover));
      if (response.meta.requestStatus === 'fulfilled') {
        handleCloseEdit();
      } else {
        console.error('Edit rover request failed:', response);
      }
    } catch (error) {
      console.error('Error editing rover:', error);
    }
  };  

  const handleDeleteRover = async () => {
    const deletedRover: ListRowData = {
      _id: selectedRoverId!,
      roverId: roverID,
      roverName: roverName,
      status: status,
      lastActive: lastActive,
    };
    dispatch(deleteRoverAsync(deletedRover));
    handleCloseDelete();
  };

  const columns: GridColDef[] = [
    { field: "roverId", headerName: "Rover ID", flex: 1 },
    { field: "roverName", headerName: "Rover Name", flex: 1 },
    { field: "status", headerName: "Status", flex: 1 },
    { field: "lastActive", headerName: "Last Active", flex: 1 },
    {
      field: "Action",
      headerName: "Action",
      headerAlign: "center",
      flex: 1,
      renderCell: (params: GridRenderCellParams<ListRowData>) => (
        <Box display="flex" justifyContent="space-evenly" width="100%">
          <BorderColorIcon
            sx={{
              color: "#25f2ee",
              marginTop: 2,
              fontSize: 24,
              cursor: "pointer",
            }}
            onClick={() => handleEditButtonClick(params.row)}
          />
          <DeleteForeverOutlinedIcon
            sx={{
              color: "red",
              marginTop: 2,
              fontSize: 24,
              cursor: "pointer",
            }}
            onClick={() => handleDeleteButtonClick(params.row)}
          />
          <SportsEsportsOutlinedIcon
            sx={{
              color: "green",
              marginTop: 2,
              fontSize: 24,
              cursor: "pointer",
            }}
            onClick={() => navigate(`/roverdrive/${params.row.roverId}`)}
          />
        </Box>
      ),
    },
  ];

  const formattedRovers = rovers.map((rover, index) => ({
    ...rover,
    id: `${index}`,
  }));

  const handleRowClick: GridEventListener<"rowClick"> = (params, event) => {
    event.stopPropagation();
  };

  const handleCellClick: GridEventListener<"cellClick"> = (params, event) => {
    if (params.field === "__check__") {
      return;
    }
    event.stopPropagation();
  };

  return (
    <div style={{ height: "calc(100vh - 90px)", width: "100%" }}>
      <div style={{ marginBottom: "20px", padding: "10px" }}>
        <h1>List Rovers</h1>
        <div style={{ display: "flex",alignItems:"flex-end", justifyContent: "end" }}>
          <div >
            <Button
              variant="contained"
              onClick={handleOpen}
              sx={{
                background: "#ffa700",
                "&:hover": {
                  background: "#ffa700",
                },
              }}
            >
              Add Rover
            </Button>
          </div>
        </div>
      </div>
      <div style={{ height: "calc(100% - 80px)" }}>
        <DataGrid
          rows={formattedRovers}
          columns={columns}
          checkboxSelection
          pagination
          onRowClick={handleRowClick}
          onCellClick={handleCellClick}
        />
      </div>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={addRoverModal}
        onClose={handleClose}
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        sx={{
          display: "flex",
          p: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
            height: "50%",
            width: "20%",
            borderRadius: "13px",
          }}
          noValidate
          autoComplete="off"
        >
          <div className="mod-AddRover">
            <p>ADD ROVER</p>
          </div>
          <TextField
            required
            id="rover-id"
            label="Rover ID"
            value={roverID}
            onChange={handleRoverIDChange}
            sx={{
              marginBottom: "20px",
            }}
          />
          <TextField
            required
            id="rover-name"
            label="Rover Name"
            value={roverName}
            onChange={handleRoverNameChange}
            sx={{
              marginBottom: "30px",
            }}
          />
          {error && (
            <Typography color="error" sx={{ mb: 2 }}>
              {error as React.ReactNode}
            </Typography>
          )}
          <Button
            variant="contained"
            onClick={handleAddRover}
            sx={{
              background: "#ffa700",
              "&:hover": {
                background: "#ffa700",
              },
            }}
          >
            ADD
          </Button>
        </Box>
      </Modal>
      <Modal
        open={edit}
        onClose={handleCloseEdit}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 5,
          }}
        >
          <h2 id="modal-modal-title">{"Edit Rover"}</h2>
          <TextField
            required
            id="rover-id"
            label="Rover ID"
            value={roverID}
            onChange={handleRoverIDChange}
            sx={{ marginBottom: "20px" }}
          />
          <TextField
            required
            id="rover-name"
            label="Rover Name"
            value={roverName}
            onChange={handleRoverNameChange}
            sx={{ marginBottom: "20px" }}
          />
          <FormControl
            sx={{ marginBottom: "20px", marginRight: 1, minWidth: 120 }}
          >
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              id="status-select"
              value={status}
              onChange={handleStatusChange}
            >
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="InActive">InActive</MenuItem>
            </Select>
          </FormControl>
          <TextField
            id="last-active"
            label="Last Active"
            type="date"
            value={lastActive}
            onChange={handleLastActiveChange}
            InputLabelProps={{ shrink: true }}
            disabled={disabled}
            sx={{ marginBottom: "20px" }}
          />
          {error && (
            <Typography color="error" sx={{ mb: 2 }}>
              {error as React.ReactNode}
            </Typography>
          )}
          <div>
            <Button
              variant="contained"
              onClick={handleEditRover}
              sx={{
                background: "#00b2e0",
                "&:hover": { background: "#00b2e0" },
              }}
            >
              Edit Rover
            </Button>
          </div>
        </Box>
      </Modal>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={opendelete}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "white",
            width: "15%",
            p: 3,
            borderRadius: "10px",
          }}
          noValidate
          autoComplete="off"
        >
          <div className="mod-AddRover">
            <h4>Confirm Remove</h4>
          </div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              marginTop: 2,
            }}
          >
            <Button
              variant="contained"
              onClick={handleCloseDelete}
              sx={{
                background: "#00b2e0",
                "&:hover": { background: "#008db2" },
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleDeleteRover}
              sx={{
                background: "#ff2e2e",
                "&:hover": { background: "#d41c1c" },
              }}
            >
              Confirm
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default DataTable;
