import React, { useState, ChangeEvent, MouseEvent, useEffect } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  Grid,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
} from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { setEmail, setPassword, getUserLogin, selectLogin } from './loginSlice'; 
import LSLogo from "../../assets/images/lawnsharkslogo.svg";
import LS1Logo from "../../assets/images/lawnshark_logo1.png";
import lawn2 from "../../assets/images/lawn2.png";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LockIcon from "@mui/icons-material/Lock";
import { AppDispatch } from '../../redux/store';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showForgetPassword, setShowForgetPassword] = useState(false);
  const [forgetPasswordEmail, setForgetPasswordEmail] = useState("");
  const navigate = useNavigate(); 

  const { email, password, isLoadingLogin, error, isAuthenticated } = useSelector(selectLogin);
  const dispatch = useDispatch<AppDispatch>();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setEmail(event.target.value));
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    dispatch(setPassword(event.target.value));
  };

  const handleLogin = () => {
    dispatch(getUserLogin(email, password));
  };

  const openForgetPasswordDialog = () => {
    setShowForgetPassword(true);
  };

  const closeForgetPasswordDialog = () => {
    setShowForgetPassword(false);
  };

  const handleForgetPasswordSubmit = () => {
    console.log("Forget Password email:", forgetPasswordEmail);
    closeForgetPasswordDialog();
  };

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate]);

  return (
    <Grid
      container
      sx={{
        height: "100vh",
        backgroundImage: `url(${lawn2})`,
        backgroundSize: 'cover',
        //backgroundColor: "cornsilk",
      }}
    >
      <Grid
    item
    xs={12}
    md={6}
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderColor: "black",
    }}
  >
    <Box
      sx={{
        width: "60%",
        height: "60vh",
        //padding: "40px",
        borderRadius: 2,
        boxShadow: "rgb(255 255 255 / 35%) 0px 5px 15px",
        backgroundColor: "#eddb85",
        //border: 2,
      }}
    >
      <Typography
        variant="h4"
        align="center"
        sx={{ fontWeight: 600, marginBottom: 2, color: "white",marginTop: "65px"}}
      >
        Login
      </Typography>
      <form>
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}
        <Box>
          <TextField
            type="email"
            variant="outlined"
            autoComplete="username"
            label="Email"
            placeholder="Enter your email"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <MailOutlineIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              backgroundColor: "white",
              "& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & textarea:-webkit-autofill, & textarea:-webkit-autofill:hover, & textarea:-webkit-autofill:focus, & select:-webkit-autofill, & select:-webkit-autofill:hover, & select:-webkit-autofill:focus": {
                WebkitTextFillColor: "#000000 !important",
                WebkitBoxShadow: "0 0 0 1000px transparent inset !important",
                backgroundColor: "transparent !important",
                transition: "background-color 5000s ease-in-out 0s !important",
              },
              width: "75%",
              borderRadius: "10px",
              left: "60px",
            }}
            value={email}
            onChange={handleEmailChange}
          />
        </Box>
        <Box sx={{ marginTop: 2 }}>
          <TextField
            variant="outlined"
            type={showPassword ? "text" : "password"}
            label="Password"
            placeholder="Enter your password"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{
              marginTop: 2,
              backgroundColor: "white",
              "& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & textarea:-webkit-autofill, & textarea:-webkit-autofill:hover, & textarea:-webkit-autofill:focus, & select:-webkit-autofill, & select:-webkit-autofill:hover, & select:-webkit-autofill:focus": {
                WebkitTextFillColor: "#000000 !important",
                WebkitBoxShadow: "0 0 0 1000px transparent inset !important",
                backgroundColor: "transparent !important",
                transition: "background-color 5000s ease-in-out 0s !important",
              },
              width: "75%",
              borderRadius: "10px",
              left: "60px",
            }}
            autoComplete="current-password"
            fullWidth
            value={password}
            onChange={handlePasswordChange}
          />
          <Typography
            variant="body2"
            component="a"
            href="#"
            onClick={openForgetPasswordDialog}
            sx={{
              fontSize: 14,
              display: "flex",
              mt: 2,
              color: "white",
              textDecoration: "none",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Forget password?
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 3,
            marginRight:0
          }}
        >
          <Button
            type="button"
            variant="outlined"
            onClick={handleLogin}
            sx={{
              backgroundColor: "#e6ff00ed",
              width: "20%",
              color: "black"
            }}
          >
            {isLoadingLogin ? "Logging in..." : "Login"}
          </Button>
        </Box>
      </form>
    </Box>
  </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundPosition: "center",
          border: "none"
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <img src={LS1Logo} alt="logo_icon" height="47px" />
        </Box>
      </Grid>

      <Dialog open={showForgetPassword} onClose={closeForgetPasswordDialog}>
        <DialogTitle>Forget Password</DialogTitle>
        <DialogContent>
          <TextField
            type="email"
            variant="outlined"
            label="Enter your email"
            fullWidth
            value={forgetPasswordEmail}
            onChange={(e) => setForgetPasswordEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeForgetPasswordDialog}>Cancel</Button>
          <Button onClick={handleForgetPasswordSubmit} variant="contained" color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Login;
