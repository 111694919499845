import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L, { LatLngExpression } from 'leaflet';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Box, Button, Typography, Paper } from '@mui/material';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

const defaultIcon = L.icon({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41],
});

L.Marker.prototype.options.icon = defaultIcon;

const LiveMap: React.FC = () => {
  const gpsData = useSelector((state: RootState) => state.rover.gpsData);
  const [position, setPosition] = useState<LatLngExpression | null>(null);
  const [isGpsDetected, setIsGpsDetected] = useState(true);
  const [path, setPath] = useState<LatLngExpression[]>([]);

  const defaultPosition: LatLngExpression = [11.061409779417795, 76.94440958427383];
  

  useEffect(() => {
    if (gpsData.latitude && gpsData.longitude && (gpsData.latitude !== 0 || gpsData.longitude !== 0)) {
      const isDegreesMultiplied = Math.abs(gpsData.latitude) > 180 || Math.abs(gpsData.longitude) > 180;
      const newPosition = isDegreesMultiplied
        ? [gpsData.latitude / 1e7, gpsData.longitude / 1e7]
        : [gpsData.latitude, gpsData.longitude];
      
      setPosition(newPosition as LatLngExpression);
      setPath((prevPath) => [...prevPath, newPosition as LatLngExpression]);
      setIsGpsDetected(true);
      console.log(`GPS ${newPosition[0]} and ${newPosition[1]}`);
    } 
  }, [gpsData]);

  const centerMapOnCurrentLocation = () => {
    if (position) {
      mapRef.current?.setView(position, mapRef.current?.getZoom() || 13);
    }
  };

  const mapRef = useRef<any>(null);

  return (
    <Box sx={{ position: 'absolute', width: '116vh', height: '65vh', mt: 21, ml: 72}}>
      <Paper elevation={3} sx={{ height: '100%', width: '100%', position: 'relative', borderRadius: 2, overflow: 'hidden' }}>
        <MapContainer
          center={position || defaultPosition}
          zoom={13}
          style={{ height: '100%', width: '100%' }}
          ref={mapRef}
        >
          <TileLayer
            url="https://{s}.api.tomtom.com/map/1/tile/basic/main/{z}/{x}/{y}.png?key=AGXSetECLL8CpXABj9xJjmtHZjpmVWra"
            attribution='&copy; <a href="https://www.tomtom.com">TomTom</a> contributors'
          />
          {position && (
            <>
              <Marker position={position}>
                <Popup>
                  GPS Location<br /> Latitude: {(position as [number, number])[0]}<br /> Longitude: {(position as [number, number])[1]}
                </Popup>
              </Marker>
              <Polyline positions={path} color="blue" />
            </>
          )}
        </MapContainer>
        <Button
          onClick={centerMapOnCurrentLocation}
          variant="contained"
          sx={{
            position: 'absolute',
            bottom: 16,
            left: 16,
            zIndex: 1000,
          }}
        >
          Center Map
        </Button>
        {!isGpsDetected && (
          <Typography
            variant="body2"
            sx={{
              position: 'absolute',
              bottom: 50,
              left: 16,
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              padding: '8px',
              borderRadius: 1,
              boxShadow: 1,
              zIndex: 1000,
              mb:1
            }}
          >
            GPS not detected
          </Typography>
        )}
      </Paper>
    </Box>
  );
};

export default LiveMap;
