import { post,deleteApi,put,get } from './apiService';
import { env_var } from '../config/env';
import { RowData,ListRowData,Roverdata,Report,ReportData,Reportgenerated, TrackingData} from '../models/rover';
import axios from 'axios';
  export const getRovers = async () => {
     const response = await fetch(`${env_var.BASE_URL}/rover/getRovers`);
    if (!response.ok) {
      throw new Error('Failed to fetch rovers');
    }
    return response.json();
  };
  
  export const addRover = async (newRover: RowData) => {
    try {
      const response = await post(`${env_var.BASE_URL}/rover/addRover`, newRover);
      console.log('Add rover response:', response);
      return response;
    } catch (error) {
      console.error('Error adding rover:', error);
      throw error;
    }   
  };

  export const editRover = async (updatedRover: ListRowData) => {
    try {
      const response = await put(`${env_var.BASE_URL}/rover/updateRover/${updatedRover._id}`, updatedRover);
      console.log('Edit rover response:', response);
      return response;
    } catch (error) {
      console.error('Error editing rover:', error);
      throw error;
    }
  };

  export const deleteRover = async (roverId: ListRowData) => {
    try {
      const response = await deleteApi (`${env_var.BASE_URL}/rover/deleteRover/${roverId._id}`);
      console.log('Delete rover response:', response);
      return response;
    } catch (error) {
      console.error('Error deleting rover:', error);
      throw error;
    }
  };

 export const getRoverStatus = async () => {
  const response = await fetch(`${env_var.BASE_URL}/rover/getRoverData`);
  if (!response.ok) {
    throw new Error('Failed to fetch rover status');
  }
  return response.json();
};

export const addRoversdata = async (roverData: Roverdata) => {
  try {
      const response = await post(`${env_var.BASE_URL}/rover/addRoverDetail`, roverData);
      console.log('Add rover detail response:', response);
      return response;
  } catch (error: any) {
      console.error('Error adding rover detail:', error);
      throw error;
  }
};

export const getTotalRoverStatus = async () => {
  const response = await fetch(`${env_var.BASE_URL}/rover/getTotalRover`);
  if (!response.ok) {
    throw new Error('Failed to fetch rover status');
  }
  return response.json();
}

export const getRoverReportStatus = async () => {
  const response = await fetch(`${env_var.BASE_URL}/rover/getReport`)
  if (!response.ok) {
    throw new Error('Failed to fetch rover status');
  }
  return response.json();
}

export const addReportStatus = async (report: ReportData) => {
  const response = await post(`${env_var.BASE_URL}/rover/addReport`,report)
  if (!response.ok) {
    throw new Error('Failed to fetch rover status');
  }
  return response.json();
}


export const editReportData = async (updatedRover: Reportgenerated) => {
  try {
    const response = await put(`${env_var.BASE_URL}/rover/updateReport/${updatedRover._id}`, updatedRover);
    console.log('Edit report response:', response);
    return response;
  } catch (error) {
    console.error('Error editing rover:', error);
    throw error;
  }
};

export const deleteReportData = async (roverId: Reportgenerated) => {
  try {
    const response = await deleteApi (`${env_var.BASE_URL}/rover/deleteReport/${roverId._id}`);
    console.log('Delete report response:', response);
    return response;
  } catch (error) {
    console.error('Error deleting rover:', error);
    throw error;
  }
};
export const saveTrackingData = async (trackingData: TrackingData) => {
  try {
   
    console.log('Tracking Data to Save:', trackingData);
    const response = await axios.post(`${env_var.BASE_URL}/tracking/save`, trackingData);
    console.log('Save Response:', response); 
    if (response.status !== 200) {
      throw new Error('Failed to save tracking data');
    }
    console.log('Data saved successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error saving tracking data:', error);
    throw error;
  }
};

export const fetchTrackingData = async (roverId: string) => {
  try {
    console.log('BASE_URL:', env_var.BASE_URL);
    console.log('Rover ID to Fetch Data for:', roverId);
    const response = await axios.get(`${env_var.BASE_URL}/tracking/fetch/${roverId}`);
    console.log('Fetch Response:', response); 
    if (response.status !== 200) {
      throw new Error('Failed to fetch tracking data');
    }
    console.log('Data fetched successfully:', response.data);
    const sortedData = response.data.sort((a: TrackingData, b: TrackingData) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime());
    return sortedData;
  } catch (error) {
    console.error('Error fetching tracking data:', error);
    throw error;
  }
};
