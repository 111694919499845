import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { MapContainer, TileLayer, Marker, Popup, Polyline, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L, { LatLngExpression } from 'leaflet';
import {
  Box,
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from '@mui/material';
import { fetchTracking, startTracking, stopTracking, saveTracking, savePathName, setTrackingData } from '../../pages/rover/roverSlice';
import { useAppDispatch } from '../../redux/store';
import { TrackingData } from '../../models/rover';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

const defaultIcon = L.icon({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  tooltipAnchor: [16, -28],
  shadowSize: [41, 41]
});

L.Marker.prototype.options.icon = defaultIcon;

const SetMapView: React.FC<{ center: [number, number], zoom: number }> = ({ center, zoom }) => {
  const map = useMap();
  useEffect(() => {
    map.setView(center, zoom);
  }, [center, zoom, map]);
  return null;
};

const formatTime = (dateString: string) => {
  const date = new Date(dateString);
  const istDate = new Date(date.getTime() + (5.5 * 60 * 60 * 1000)); 
  const hours = istDate.getHours();
  const minutes = istDate.getMinutes().toString().padStart(2, '0');
  const seconds = istDate.getSeconds().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12; 
  return `${formattedHours}:${minutes}:${seconds} ${ampm}`;
};

const GPSHistory: React.FC<{ roomID: string }> = ({ roomID }) => {
  const dispatch = useAppDispatch();
  const gpsHistory = useSelector((state: RootState) => state.rover.gpsHistory);
  const trackingData = useSelector((state: RootState) => state.rover.trackingData);
  const isTracking = useSelector((state: RootState) => state.rover.isTracking[roomID]);
  const [isMapVisible, setMapVisible] = useState(false);
  const [mapCenter, setMapCenter] = useState<[number, number]>([51.505, -0.09]);
  const [mapZoom, setMapZoom] = useState(13);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [pathName, setPathName] = useState('');
  const [selectedTrackingItem, setSelectedTrackingItem] = useState<TrackingData | null>(null);
  const [selectedStartTime, setSelectedStartTime] = useState<string | null>(null);
  const [position, setPosition] = useState<LatLngExpression | null>(null);
  const [path, setPath] = useState<LatLngExpression[]>([]);
  const mapRef = useRef<any>(null);

  useEffect(() => {
    dispatch(fetchTracking(roomID));
  }, [dispatch, roomID]);

  useEffect(() => {
    if (isMapVisible && selectedTrackingItem && selectedTrackingItem.path.length) {
      const firstPoint = selectedTrackingItem.path[0];
      setMapCenter([firstPoint.latitude / 1e7, firstPoint.longitude / 1e7]);
      setMapZoom(13);
      setPath(selectedTrackingItem.path.map(point => [point.latitude / 1e7, point.longitude / 1e7] as LatLngExpression));
    }
  }, [selectedTrackingItem, isMapVisible]);

  const handleStartTracking = () => {
    const newTracking = {
      roverId: roomID,
      startTime: new Date().toISOString(),
      path: [],
      pathName: 'No Name',
    };
    dispatch(startTracking(roomID));
    dispatch(setTrackingData([newTracking, ...trackingData]));
  };

  const handleStopTracking = () => {
    const currentTracking = trackingData.find(track => track.roverId === roomID && !track.endTime);
    if (currentTracking) {
      setSelectedStartTime(currentTracking.startTime);
    }
    dispatch(stopTracking(roomID));
    setDialogOpen(true);
  };
  const handleSavePathName = () => {
    const nameToSave = pathName.trim() !== '' ? pathName : 'No Name';
    if (selectedStartTime) {
      dispatch(savePathName({ roverId: roomID, pathName: nameToSave, startTime: selectedStartTime }));
      const selectedTrackingData = trackingData.find(track => track.roverId === roomID && track.startTime === selectedStartTime);
      if (selectedTrackingData) {
        const updatedTrackingData = { ...selectedTrackingData, pathName: nameToSave };
        dispatch(saveTracking(updatedTrackingData));

        const updatedTrackingList = trackingData.filter(track => track.startTime !== selectedStartTime);
        dispatch(setTrackingData([updatedTrackingData, ...updatedTrackingList]));
      }
    }
    setDialogOpen(false);
    setPathName('');
  };

  const handleCancel = () => {
    setDialogOpen(false);
    setPathName('');
  };

  const handleViewTracking = (trackingItem: TrackingData) => {
    const selectedTrackingData = trackingData.find(track => track.startTime === trackingItem.startTime && track.roverId === trackingItem.roverId);
    if (selectedTrackingData) {
      if (selectedTrackingData.path.length) {
        const firstPoint = selectedTrackingData.path[0];
        setMapCenter([firstPoint.latitude / 1e7, firstPoint.longitude / 1e7]);
      } else {
        setMapCenter([51.505, -0.09]); // Default center if no path is available
      }
      setMapZoom(13);
      setSelectedTrackingItem(selectedTrackingData);
      setPath(selectedTrackingData.path.map(point => [point.latitude / 1e7, point.longitude / 1e7] as LatLngExpression));
      setMapVisible(true);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const centerMapOnCurrentLocation = () => {
    if (position) {
      mapRef.current?.setView(position, mapRef.current?.getZoom() || 13);
    }
  };

  return (
    <Box p={2} position={'absolute'} mr={-12}>
      <Box sx={{ display: 'flex', gap: 2, mt: -18 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleStartTracking}
          disabled={isTracking}
        >
          Start Tracking
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleStopTracking}
          disabled={!isTracking}
        >
          Stop Tracking
        </Button>
      </Box>
      <TableContainer component={Paper} sx={{ marginTop: '20px', maxHeight: '400px', width: "600px", position: 'fixed' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Rover ID</TableCell>
              <TableCell>Start Time</TableCell>
              <TableCell>End Time</TableCell>
              <TableCell>Path Name</TableCell>
              <TableCell>View GPS</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {trackingData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((track, index) => (
              <TableRow key={index}>
                <TableCell>{track.roverId}</TableCell>
                <TableCell>{formatTime(track.startTime)}</TableCell>
                <TableCell>{track.endTime ? formatTime(track.endTime) : 'In Progress'}</TableCell>
                <TableCell>{track.pathName || 'No Name'}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleViewTracking(track)}
                    disabled={!track.endTime}
                  >
                    View
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={trackingData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      {isMapVisible && selectedTrackingItem && (
         <Box sx={{ position: 'absolute', width: '116vh', height: '66vh', mt: -5, ml: 0 }}>
                    <Paper elevation={3} sx={{ width: '100%', height: '100%' }}>

          <MapContainer center={mapCenter} zoom={mapZoom} scrollWheelZoom={false} ref={mapRef} style={{ height: '100%', width: '100%' }}>
            <SetMapView center={mapCenter} zoom={mapZoom} />
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {selectedTrackingItem.path.map((point, index) => (
              <Marker
                key={index}
                position={[point.latitude / 1e7, point.longitude / 1e7]}
              >
                <Popup>{`Lat: ${point.latitude / 1e7}, Lng: ${point.longitude / 1e7}`}</Popup>
              </Marker>
            ))}
            <Polyline positions={path} color="blue" />
          </MapContainer>
          </Paper>
          <Button
              onClick={() => setMapVisible(false)}
              variant="contained"
              sx={{
                position: 'absolute',
                bottom: 16,
                left: 16,
                zIndex: 1000
              }}
            >
              Hide Map
            </Button>
        </Box>
      )}
      <Dialog open={isDialogOpen} onClose={handleCancel}>
        <DialogTitle>Tracking Name</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Tracking Name"
            fullWidth
            value={pathName}
            onChange={(e) => setPathName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSavePathName} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );  
};

export default GPSHistory;
