import React from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes/Router";
import {store} from './redux/store';
import { Provider } from 'react-redux'

const App = () => {
  return (
    <React.StrictMode>
      <Provider store={store}>
      <BrowserRouter>
        <Router/>
      </BrowserRouter>
      </Provider>
    </React.StrictMode>
  );
};

export default App;
