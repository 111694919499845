import { createSlice, PayloadAction, createAsyncThunk, createSelector } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../redux/store';
import { getRovers, addRover, editRover, deleteRover } from '../../services/roverService';
import { RowData,ListRowData } from '../../models/rover';
interface Rover {
    _id: string;
    roverId: string;
    roverName: string;
    status: string;
    lastActive: string;
}

interface listRovers {
  _id: string;
  roverId: string;
  roverName: string;
  status: string;
  lastActive: string;
}

interface ListRoverState {
  rovers: Rover[];
  isLoading: boolean;
  error: string | object | null;
  listRoverState:"idle" | "loading" | "failed" | "succeeded";
  addRoverState:"idle" | "loading" | "failed" | "succeeded";
}

const initialState: ListRoverState = {
  rovers: [],
  isLoading: false,
  error: null,
  listRoverState:"idle",
  addRoverState:"idle"
};

export const fetchRoversAsync = createAsyncThunk('listRover/getRovers', async (any) => {
  const response = await getRovers();
  return response;
});

export const addRoverAsync = createAsyncThunk('listRover/addRover', async (newRover: Rover, thunkAPI) => {
    try {
        const response = await addRover(newRover);
        return response;
    } catch (error) {
        console.error('Error adding rover:', error.message);
        return thunkAPI.rejectWithValue(error.message);
    }
});
  

export const editRoverAsync = createAsyncThunk('listRover/updateRover', async (updatedRover: ListRowData) => {
    try {
      const response = await editRover(updatedRover);
      console.log("edit",response);
      return response;
    } catch (error) {
      console.error('Error editing rover:', error);
      throw error
    }
});

export const deleteRoverAsync = createAsyncThunk('listRover/deleteRover', async (roverId: ListRowData) => {
    try {
      const response = await deleteRover(roverId);
      console.log("delete",response);
      return response;
    } catch (error) {
      console.error('Error deleting rover:', error);
      throw error;
    }
});

const listRoverSlice = createSlice({
  name: 'listRover',
  initialState,
  reducers: {
    clearError: state => {
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoversAsync.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchRoversAsync.fulfilled, (state, action: PayloadAction<Rover[]>) => {
        state.isLoading = false;
        state.rovers = action.payload;
      })
      .addCase(fetchRoversAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to fetch rovers';
      })
      .addCase(addRoverAsync.pending, (state) => {
        state.isLoading = true;
        state.addRoverState = "loading";
        state.error = null;
      })
      .addCase(addRoverAsync.fulfilled, (state, action: PayloadAction<Rover>) => {
        state.isLoading = false;
        state.addRoverState = "succeeded";
        state.rovers.push(action.payload);
      })
      .addCase(addRoverAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.addRoverState = "failed";
        state.error = action.payload || 'Failed to add rover';
      })      
      .addCase(editRoverAsync.pending, (state) => {
        state.isLoading = true;
        state.listRoverState = "loading";
        state.error = null;
      })
      .addCase(editRoverAsync.fulfilled, (state, action: PayloadAction<Rover>) => {
        state.isLoading = false;
        state.listRoverState = "succeeded";
        state.rovers.push(action.payload);
      })
      .addCase(editRoverAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.addRoverState = "failed";
        state.error = action.error.message || 'Failed to edit rover';
      })
      .addCase(deleteRoverAsync.pending, (state) => {
        state.isLoading = true;
        state.listRoverState = "loading";
        state.error = null;
      })
      .addCase(deleteRoverAsync.fulfilled, (state, action: PayloadAction<Rover>) => {
        state.isLoading = false;
        state.listRoverState = "succeeded";
        state.rovers.push(action.payload);
      })
      .addCase(deleteRoverAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.listRoverState = "failed";
        state.error = action.error || 'Failed to delete rover';
      })
  },
});

export const { clearError } = listRoverSlice.actions;
export const selectRovers = (state: RootState) => state.listRover;
function selectState(state: RootState)
{
    return state.listRover;
}
export const selectrovers = createSelector(
    selectState,
    state => state.rovers    
);
export const updaterovers = createSelector(
    selectState,
    state => state.listRoverState    
);
export const addroverstate = createSelector(
    selectState,
    state => state.addRoverState    
);
export const errorState = createSelector(
  selectState,
  state => state.error    
);

export default listRoverSlice.reducer;
