import { post } from './apiService';
import { env_var } from '../config/env';

export const getlogin = async function (email: string, password: string) {
  try {
    const response = await post(`${env_var.BASE_URL}/users/login`, { email, password });
    console.log("response", response);
    return response;
  } catch (error) {
    console.error('Error fetching login:', error);
    console.error('Error data login:', error.data);
    throw error;
  }
};
